import React from "React";
import Layout from "../components/Global/Layout";
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Img from "gatsby-image"

import "../assets/styles/projects.scss"
import placeholder from "../assets/images/blog-placeholder.jpg"


export const query = graphql`
  query Projects {
    allWpProject {
        nodes {
          id
          projectMeta {
            projectName
            projectDescription
            projectType
            projectImage {
              id
              remoteFile {
                id
                childImageSharp {
                  id
                  fluid(maxWidth: 530, maxHeight: 350) {
                    base64
                    tracedSVG
                    srcWebp
                    srcSetWebp
                    originalImg
                    originalName
                  }
                }
              }
            }
          }
        }
      }
  }
`

export default (data) => {


    const current = "projects"
    const projectData = data.data.allWpProject.nodes

    console.log(projectData)

    return(
        <>
        <Layout current={current}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Projects - Develescope</title>
          <meta name="description" content="Here you can find a portfolio of a lot of things we have done, such as some of the services we provide, projects we have done, and products we have in store." />
        </Helmet>
        <section className="section projects-head">
            <div className="container">
                <div className="top-txt">
                    <h1>Stuff we built</h1>
                    <p>Here you can find a portfolio of a lot of things we have done, such as some of the services we provide, projects we have done, and products we have in store.</p>
                </div>
            </div>
        </section>
        <section className="section all-projects">
            <div className="container">

            {projectData.length ? (
                    projectData.map((project)  => 

                    <div className="columns is-vcentered project" key={project.id}>
                        <div className="column is-6">
                            {project.projectMeta.projectImage ? 
                            <Img fluid={project.projectMeta.projectImage.remoteFile.childImageSharp.fluid} />
                            :
                            <img src={placeholder} alt=""/>
                            }
                        </div>
                        <div className="column is-6">
                            <h4><i/> {project.projectMeta.projectType}</h4>
                            <h3>{project.projectMeta.projectName}</h3>
                            <p>{project.projectMeta.projectDescription}</p>
                        </div>
                    </div>

            )) : ''}

            </div>
        </section>








            {/* {serviceData.length ? (
                    serviceData.map((service)  => 

                    <div className="column is-half" key={service.id}>
                      <div className="serv-wrap">
                        <h3>{service.title}</h3>
                        <h5><i/>Learn More</h5>
                        <p>{service.serviceMeta.serviceDescription}</p>
                      </div>
                    </div>

            )) : ''} */}

        </Layout>
        </>
    )
}